<script setup>
defineProps({ blok: Object });
const { links } = useUtils();
</script>

<template>
  <div v-editable="blok" class="section cta-section">
    <div class="container">
      <div
        data-w-id="fe26eb8f-dc93-7853-70fd-9b6d6a9ae2b3"
        class="cta-follow"
        style="opacity: 1"
      >
        <div class="follow-cta-headings">
          <div class="cta-follow-headline white-color">
            {{ blok.headline || "Keep up with the latest updates!" }}<br />
          </div>
          <div class="cta-follow-headline">
            {{
              blok.text ||
              "Connect with the community to discover how others maximize the potential of AI. 🚀"
            }}<br />
          </div>
        </div>
        <div class="follow-box-grid">
          <div
            v-for="(item, i) in blok.blocks"
            :key="item._uid"
            class="content"
          >
            <div v-editable="item" class="follow-box w-inline-block">
              <div class="follow-box-content w-[70%]">
                <div>
                  <h2 class="feature-bento-box-title">
                    {{ item.title || "X / Twitter" }}
                  </h2>
                  <div class="feature-bento-box-summary">
                    {{
                      item.text ||
                      "Keep up to date with the latest releases, features and improvements."
                    }}
                  </div>
                </div>
                <div class="feature-bento-box-button">
                  <div class="button-link">
                    <div
                      v-for="(link, index) in item?.buttons"
                      :key="index"
                      class="hero-button-wrap"
                    >
                      <StoryblokComponent :blok="link" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="follow-box-icon-wrap w-[30%]">
                <NuxtImg
                  v-if="item.icon?.filename"
                  width="160"
                  :src="item.icon.filename"
                  :alt="`Icon - ${item.title}`"
                  class="follow-box-icon"
                  loading="lazy"
                />
                <!-- <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="64"
                  height="64"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  app="ikonik"
                  class="follow-box-icon"
                >
                  <path
                    d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"
                    app="ikonik"
                  ></path>
                </svg> -->
              </div>
            </div>
          </div>
        </div>

        <div class="follow-bg-wrap"><div class="follow-bg-2"></div></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.follow-box {
  &:hover .follow-box-icon-wrap {
    opacity: 1;
  }

  .follow-box-icon-wrap {
    opacity: 0.7;
    transition: all 0.4s;
  }
}
.follow-box-icon {
  object-fit: contain;
  min-width: 120px;
}
</style>
